<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row justify="end">
          <v-col cols="12" sm="6" class="text-left">
            <v-row>
              <v-col cols="12" sm="4">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="grey"> tag </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Total Pallets </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-chip small>
                      {{ totalPallets }}
                    </v-chip>
                  </v-list-item-action>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="4">
                <v-list-item @click="addToFilter('Allocated', 'stockStatus')">
                  <v-list-item-action>
                    <v-icon color="cyan"> fact_check </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Allocated </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-chip small>
                      {{ allocatedPallets }}
                    </v-chip>
                  </v-list-item-action>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="4">
                <v-list-item @click="addToFilter('Stuff Discrepancy', 'stockStatus')">
                  <v-list-item-action>
                    <v-icon color="red"> error </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Issues </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-chip small>
                      {{ issuePallets }}
                    </v-chip>
                  </v-list-item-action>
                </v-list-item>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="text-right">
            <v-btn
              v-if="selectedItems.length > 0"
              small
              rounded class="mx-2"
              color="teal"
              style="text-transform: none"
              @click="viewOrderItems()"
            >
              View Items
            </v-btn>
            <v-chip
              style="height: 40px"
              outlined
              small
              class="pr-1 text-center"
            >
              <v-text-field
                placeholder="Search"
                class="mb-1"
                hide-details
                rounded
                clearable
                dense
                style="width: 300px"
                v-model="search"
              ></v-text-field>
            </v-chip>
          </v-col>
        </v-row>
        <v-card-text style="max-width: 100%; overflow-x: auto" class="my-0">
          <div :style="{ width: tableWidth }" v-if="displayData.length > 0">
            <table :width="'100%'">
              <tr class="mx-0 px-0">
                <td
                  class="text-center mx-0 px-0"
                  style="
                    cursor: grab;
                    border-right: 1px solid grey;
                    min-height: 20px;
                    min-width: 20px;
                  "
                  v-for="(header, index) in headers"
                  :key="index"
                  :width="header.width ?? '150px'"
                >
                  <div>
                    <v-row align="center" justify="center" class="py-2">
                      <v-checkbox
                        v-if="header.value == 'actions'"
                        :indeterminate="
                          selectedItems.length > 0 &&
                          selectedItems.length < filteredResults.length
                        "
                        hide-details
                        v-model="selectAllItems"
                        dense
                        class="mx-0 px-0 mb-2"
                      ></v-checkbox>
                      <b style="font-size: 12px">{{ header.text }}</b>
                      <v-btn
                        v-if="!header.disableSort"
                        class="ml-1"
                        icon
                        x-small
                        @click="changeSortBy(header.value)"
                      >
                        <v-icon v-if="sortBy != header.value" small color="grey"
                          >arrow_upward</v-icon
                        >
                        <v-icon v-else-if="sortDesc" small
                          >arrow_downward</v-icon
                        >
                        <v-icon v-else small>arrow_upward</v-icon>
                      </v-btn>
                      <v-badge
                        v-if="!header.disableFilter"
                        :content="
                          filters[header.value]
                            ? filters[header.value].length
                            : false
                        "
                        :value="
                          filters[header.value] &&
                          filters[header.value].length > 0
                        "
                        color="primary"
                        offset-y="10"
                      >
                        <v-menu offset-y left :close-on-content-click="false">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              x-small
                              color="grey"
                              icon
                              ><v-icon small>filter_alt</v-icon></v-btn
                            >
                          </template>
                          <v-card
                            style="
                              background: var(--v-modal-base) !important;
                              border-radius: 20px;
                            "
                          >
                            <v-card-text>
                              <v-list
                                dense
                                style="
                                  background: var(--v-modal-base) !important;
                                  border-radius: 20px;
                                "
                              >
                                <v-text-field
                                  hide-details
                                  rounded
                                  dense
                                  outlined
                                  clearable
                                  prepend-inner-icon="search"
                                  placeholder="Search"
                                  autofocus
                                  v-model="searchHeaders[header.value]"
                                  @input="updateSearchKey()"
                                ></v-text-field>
                                <v-list
                                  dense
                                  style="
                                    max-height: 50vh;
                                    overflow-y: auto;
                                    background: var(--v-modal-base) !important;
                                  "
                                  :key="searchKey"
                                >
                                  <v-list-item
                                    v-for="(item, index) in headerOptions(
                                      header.value
                                    )"
                                    :key="index"
                                  >
                                    <v-list-item-action class="pr-0">
                                      <v-checkbox
                                        v-model="filters[header.value]"
                                        :value="item.value"
                                        hide-details
                                        dense
                                        @change="calculateFilteredResults()"
                                      ></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        {{ item.name }}
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item
                                    v-if="
                                      headerOptions(header.value).length == 0
                                    "
                                  >
                                    <v-list-item-content class="text-center">
                                      <span style="font-size: 12px"
                                        >No results found</span
                                      >
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-list>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </v-badge>
                    </v-row>
                  </div>
                </td>
              </tr>
            </table>
            <v-divider></v-divider>
            <div
              class="mt-1 pt-1 px-1"
              :style="{ width: '100%' }"
              v-if="displayData.length > 0"
            >
              <v-virtual-scroll
                v-if="displayData.length > 0"
                :bench="10"
                :items="displayData"
                :height="calculatedHeight"
                :width="'100%'"
                item-height="35"
                class="px-1"
              >
                <template v-slot:default="{ item }">
                  <div
                    class="text-center my-0 py-0"
                    :style="{
                      display: 'flex',
                      'flex-direction': 'row',
                      width: '100%',
                      'background-color':
                        selectedItem && selectedItem.id == item.id
                          ? 'rgba(12, 97, 24, 0.6)'
                          : 'transparent',
                    }"
                  >
                    <td
                      v-for="(header, index) in headers"
                      :key="index"
                      :style="{ width: header.width ?? '150px' }"
                      class="text-center mt-0 mb-0 pb-0"
                    >
                      <div v-if="header.value == 'actions'">
                        <v-row align="center" justify="center" class="py-1">
                          <v-checkbox
                            hide-details
                            dense
                            :value="
                              selectedItems.map((x) => x.id).includes(item.id)
                            "
                            @change="selectItem(item)"
                            class="mb-2 mx-0 px-0"
                          ></v-checkbox>
                          <v-btn icon @click="viewOrderItem(item)" small>
                            <v-icon small>launch</v-icon>
                          </v-btn>
                        </v-row>
                      </div>
                      <div v-else-if="header.value == 'orderStatus'">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              :color="orderStatusColor(item.orderStatus)"
                              small
                              icon
                              v-on="on"
                              @click="
                                addToFilter(item.orderStatus, 'orderStatus')
                              "
                            >
                              <v-icon>{{
                                orderStatusIcon(item.orderStatus)
                              }}</v-icon>
                            </v-btn>
                          </template>
                          <span style="font-size: 12px">{{
                            $Format.capitalizeFirstLetter(item.orderStatus)
                          }}</span>
                        </v-tooltip>
                      </div>
                      <div
                        v-else-if="header.value == 'state'"
                        class="text-center"
                      >
                        <!-- {{ item.state }} -->
                        <v-tooltip top v-if="item.state">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              :color="bookingStatusColor(item.state)"
                              small
                              icon
                              v-on="on"
                              @click="addToFilter(item.state, 'state')"
                            >
                              <v-icon>{{
                                bookingStatusIcon(item.state)
                              }}</v-icon>
                            </v-btn>
                          </template>
                          <span style="font-size: 12px">{{
                            $Format.capitalizeFirstLetter(item.state)
                          }}</span>
                        </v-tooltip>
                      </div>
                      <div v-else-if="header.value == 'stockStatus'">
                        <v-chip
                          v-if="item.stockStatus"
                          x-small
                          @click="addToFilter(item.stockStatus, 'stockStatus')"
                          :color="orderItemStatusColor(item.stockStatus)"
                        >
                          <v-icon x-small left>
                            {{ orderItemStatusIcon(item.stockStatus) }}
                          </v-icon>
                          {{ item.stockStatus }}
                        </v-chip>
                      </div>
                      <div v-else-if="header.value == 'portOfLoadValue'">
                        <v-tooltip top v-if="item.portOfLoadValue">
                          <template v-slot:activator="{ on }">
                            <v-chip
                              outlined
                              style="border: none; font-size: 11px"
                              v-on="on"
                              small
                              @click="
                                addToFilter(
                                  item.portOfLoadValue,
                                  'portOfLoadValue'
                                )
                              "
                            >
                              <v-avatar size="20" left>
                                <v-img
                                  contain
                                  :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue
                                    .substring(0, 2)
                                    .toLowerCase()}.svg`"
                                ></v-img>
                              </v-avatar>
                              {{ item.portOfLoadValue }}
                            </v-chip>
                          </template>
                          <span style="font-size: 12px">{{
                            item.portOfLoadCity
                          }}</span>
                        </v-tooltip>
                      </div>
                      <div v-else-if="header.value == 'portOfDischargeValue'">
                        <v-tooltip top v-if="item.portOfDischargeValue">
                          <template v-slot:activator="{ on }">
                            <v-chip
                              outlined
                              style="border: none; font-size: 11px"
                              v-on="on"
                              small
                              @click="
                                addToFilter(
                                  item.portOfDischargeValue,
                                  'portOfDischargeValue'
                                )
                              "
                            >
                              <v-avatar size="20" left>
                                <v-img
                                  contain
                                  :src="`https://cdn.loglive.io/flags/4x3/${item.portOfDischargeValue
                                    .substring(0, 2)
                                    .toLowerCase()}.svg`"
                                ></v-img>
                              </v-avatar>
                              {{ item.portOfDischargeValue }}
                            </v-chip>
                          </template>
                          <span style="font-size: 12px">{{
                            item.portOfDischargeCity
                          }}</span>
                        </v-tooltip>
                      </div>
                      <div v-else-if="header.value == 'finalDestinationValue'">
                        <v-tooltip top v-if="item.finalDestinationValue">
                          <template v-slot:activator="{ on }">
                            <v-chip
                              outlined
                              style="border: none; font-size: 11px"
                              v-on="on"
                              small
                              @click="
                                addToFilter(
                                  item.finalDestinationValue,
                                  'finalDestinationValue'
                                )
                              "
                            >
                              <v-avatar size="20" left>
                                <v-img
                                  contain
                                  :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue
                                    .substring(0, 2)
                                    .toLowerCase()}.svg`"
                                ></v-img>
                              </v-avatar>
                              {{ item.finalDestinationValue }}
                            </v-chip>
                          </template>
                          <span style="font-size: 12px">{{
                            item.finalDestinationCity
                          }}</span>
                        </v-tooltip>
                      </div>

                      <div
                        v-else-if="header.value == 'buyerName'"
                        class="text-left"
                      >
                        <v-tooltip top v-if="item.buyer">
                          <template v-slot:activator="{ on }">
                            <v-chip
                              v-if="item.buyer"
                              small
                              class="primaryText--text"
                              v-on="on"
                              outlined
                              @click="addToFilter(item.buyerName, 'buyerName')"
                              style="border: none"
                            >
                              <v-avatar
                                size="20"
                                :color="
                                  item.buyer && item.buyer.logo
                                    ? 'white'
                                    : 'secondary'
                                "
                                left
                              >
                                <v-img
                                  v-if="item.buyer.logo"
                                  :src="item.buyer.logo"
                                  contain
                                ></v-img>
                                <h3 v-else style="color: white">
                                  {{ item.buyerName.charAt(0) }}
                                </h3>
                              </v-avatar>
                              {{ item.buyerName }}
                            </v-chip>
                          </template>
                          <span>{{ item.buyer.name }}</span>
                        </v-tooltip>
                      </div>

                      <div
                        v-else-if="header.value == 'consigneeName'"
                        class="text-left"
                      >
                        <v-tooltip top v-if="item.consignee">
                          <template v-slot:activator="{ on }">
                            <v-chip
                              v-if="item.consignee"
                              small
                              class="primaryText--text"
                              v-on="on"
                              outlined
                              @click="
                                addToFilter(item.consigneeName, 'consigneeName')
                              "
                              style="border: none"
                            >
                              <v-avatar
                                size="20"
                                :color="
                                  item.consignee && item.consignee.logo
                                    ? 'white'
                                    : 'secondary'
                                "
                                left
                              >
                                <v-img
                                  v-if="item.consignee.logo"
                                  :src="item.consignee.logo"
                                  contain
                                ></v-img>
                                <h3 v-else style="color: white">
                                  {{ item.consigneeName.charAt(0) }}
                                </h3>
                              </v-avatar>
                              {{ item.consigneeName }}
                            </v-chip>
                          </template>
                          <span>{{ item.consignee.name }}</span>
                        </v-tooltip>
                      </div>

                      <div v-else>
                        <v-chip
                          v-if="item[header.value]"
                          small
                          class="primaryText--text"
                          outlined
                          @click="addToFilter(item[header.value], header.value)"
                          style="border: none"
                        >
                          {{ item[header.value] }}
                        </v-chip>
                      </div>
                    </td>
                  </div>

                  <v-divider class="my-0 py-0"></v-divider>
                </template>
              </v-virtual-scroll>
            </div>
          </div>
          <div class="container" v-if="!loading && displayData.length == 0">
            <h3 style="color: grey">No Results Found</h3>
          </div>
          <div class="container" v-else-if="loading && displayData.length == 0">
            <valhalla-loading height="500px" />
          </div>
        </v-card-text>
      </v-card-text>
    </v-card>

    <v-dialog v-model="itemModal" fullscreen>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            Selected Items Overview
            <!-- <span class="headline">Order Item: {{ selectedItem.ctoNo }}</span> -->
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <div  v-if="displayItem.allowConfirm">
            <v-btn outlined color="success" rounded class="mx-2" @click="
                    (selectedStatus = 'Confirmed'),
                      (confirmStatusModal = true)
                  ">Confirm</v-btn>
          <v-btn outlined color="red" rounded class="mx-2" @click="
                    (selectedStatus = 'Rejected'),
                      (confirmStatusModal = true)
                  ">Reject</v-btn>
          </div>
        
          <!-- <v-btn icon @click="saveItem()" :loading="saving" color="secondary">
              <v-icon>save</v-icon>
            </v-btn> -->
          <v-btn text @click="itemModal = false"> X </v-btn>
        </v-toolbar>
        <v-card-text
          class="text-center"
          style="max-width: 100%; overflow-x: auto"
        >
          <v-list dense>
            <v-row>
              <v-col cols="12" sm="6">
                <v-container style="height: 40px">
                  <v-row justify="space-between" align="center">
                    <v-col cols="12" sm="4" v-if="displayItem.vesselVoyage">
                      <v-list-item>
                        <v-list-item-action>
                          <v-icon color="grey">directions_boat</v-icon>
                        </v-list-item-action>
                        <v-list-item-content class="text-left">
                          <v-list-item-title
                            >{{ displayItem.vesselVoyage }}
                          </v-list-item-title>
                          <v-list-item-subtitle> Vessel </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="4" v-if="displayItem.buyer">
                      <v-list-item>
                        <v-list-item-action>
                          <v-icon color="grey">shopping_cart</v-icon>
                        </v-list-item-action>
                        <v-list-item-content class="text-left">
                          <v-list-item-title
                            >{{ displayItem.buyer }}
                          </v-list-item-title>
                          <v-list-item-subtitle> Buyer </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="4" v-if="displayItem.consignee">
                      <v-list-item>
                        <v-list-item-action>
                          <v-icon color="grey">call_received</v-icon>
                        </v-list-item-action>
                        <v-list-item-content class="text-left">
                          <v-list-item-title
                            >{{ displayItem.consignee }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Consignee
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-col cols="12" sm="6" class="text-center my-0 py-0">
                <v-container style="height: 40px">
                  <v-row justify="space-between" align="center">
                    <v-chip class="ma-1">
                      {{ totalSummaryPallets() }}
                      Pallets
                    </v-chip>
                    <v-chip class="ma-1">
                      {{ totalSummaryCartons() }}
                      Cartons
                    </v-chip>
                    <!-- <v-chip class="ma-1">
                      Gross Weight: 
                      KGS
                    </v-chip> -->
                    <v-col cols="12" sm="3">
                      <v-list-item style="height: 40px">
                        <v-list-item-action>
                          <v-icon>scale</v-icon>
                        </v-list-item-action>
                        <v-list-item-content class="text-left">
                          <v-list-item-title style="font-size: 14px">
                            {{ totalNettWeight() }} KGS
                          </v-list-item-title>
                          <v-list-item-subtitle style="font-size: 12px">
                            Nett Weight
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-list-item style="height: 40px">
                        <v-list-item-action>
                          <v-icon>scale</v-icon>
                        </v-list-item-action>
                        <v-list-item-content class="text-left">
                          <v-list-item-title style="font-size: 14px">
                            {{ totalGrossWeight() }} KGS
                          </v-list-item-title>
                          <v-list-item-subtitle style="font-size: 12px">
                            Gross Weight
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-progress-linear
                v-if="loadingPallets"
                color="primary"
                indeterminate
              ></v-progress-linear>
              <v-col cols="12" class="ma-0 pa-0">
                <v-toolbar flat dense>
                  <v-tabs height="50px" v-model="palletTab">
                    <v-tabs-slider color="primary"></v-tabs-slider>
                    <v-tab>Summary</v-tab>
                    <v-tab>Content</v-tab>
                  </v-tabs>
                  <v-spacer></v-spacer>
                  <v-chip
                    v-if="palletTab == 1"
                    style="height: 40px"
                    outlined
                    small
                    class="pr-1 text-center"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn small icon v-on="on" :loading="downloadingPalletExport" @click="downloadPalletExport()"
                          ><v-icon small>download</v-icon></v-btn
                        >
                      </template>
                      Export to Excel
                    </v-tooltip>
                    <v-text-field
                      placeholder="Search"
                      class="mb-1"
                      hide-details
                      rounded
                      clearable
                      dense
                      style="width: 400px"
                      v-model="searchPallets"
                    ></v-text-field>
                  </v-chip>
                </v-toolbar>
              </v-col>
              <v-col cols="12" v-if="palletTab == 0">
                <table
                  :key="productKey"
                  style="width: 100%; max-height: 30vh; overflow-y: auto"
                >
                  <thead style="position: sticky; width: 100%">
                    <draggable
                      v-model="productSummaryHeaders"
                      tag="tr"
                      :style="{ cursor: columnDrag ? 'grabbing' : 'grab' }"
                      :animation="200"
                      ghost-class="ghost-card"
                      @start="columnDrag = true"
                      @end="columnDrag = false"
                      @change="updateHeaderSelection()"
                    >
                      <th
                        class="text-center"
                        style="cursor: grab"
                        v-for="(header, index) in selectedItems.length > 0
                          ? productSummaryHeaders
                          : productSummaryHeaders"
                        :key="index"
                      >
                        <b style="font-size: 11px">{{ header.text }}</b>
                      </th>
                    </draggable>
                  </thead>
                  <tbody style="height: 50vh; overflow-y: auto">
                    <tr v-for="(item, i) in productBreakdown" :key="i">
                      <template v-for="(header, index) in displayHeaders">
                        <td
                          :key="index"
                          style="font-size: 11px"
                          v-if="
                            header.value != 'cartonPrice' &&
                            header.value != 'kgPrice' &&
                            item[header.value] != null
                          "
                          :style="{ border: '0.4px solid rgb(95, 95, 95)' }"
                          :rowspan="
                            item[header.value + '_rowspan']
                              ? item[header.value + '_rowspan']
                              : 1
                          "
                          class="text-center"
                        >
                          <v-btn
                            x-small
                            class="mr-1"
                            style="text-transform: none"
                            text
                          >
                            {{ item[header.value] }}
                          </v-btn>
                        </td>
                        <td
                          style="font-size: 11px"
                          v-else-if="item[header.value] != null"
                          :key="index"
                          class="text-center"
                        >
                          <v-icon color="amber">lock</v-icon>
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </v-col>
              <v-col
                cols="12"
                v-else-if="palletTab == 1"
                class="mx-0 px-0"
                style="max-width: 100%; overflow-x: auto"
              >
                <div :style="{ width: palletTableWidth }" class="mt-2 mx-1">
                  <table :width="'100%'">
                    <tr>
                      <td
                        class="text-center mx-0 px-0"
                        style="
                          cursor: grab;
                          border-right: 1px solid grey;
                          min-height: 20px;
                          min-width: 20px;
                        "
                        v-for="(header, index) in palletHeaders"
                        :key="index"
                        :width="header.width ?? '100px'"
                      >
                        <div>
                          <v-row align="center" justify="center" class="py-2">
                            <b style="font-size: 11px">{{ header.text }}</b>
                            <v-btn
                              v-if="!header.disableSort"
                              class="ml-1"
                              icon
                              x-small
                              @click="changePalletSortBy(header.value)"
                            >
                              <v-icon
                                v-if="sortPalletBy != header.value"
                                x-small
                                color="grey"
                                >arrow_upward</v-icon
                              >
                              <v-icon v-else-if="sortDesc" x-small
                                >arrow_downward</v-icon
                              >
                              <v-icon v-else small>arrow_upward</v-icon>
                            </v-btn>
                            <v-badge
                              v-if="!header.disableFilter"
                              :content="
                                palletFilters[header.value]
                                  ? palletFilters[header.value].length
                                  : false
                              "
                              dense
                              :value="
                                palletFilters[header.value] &&
                                palletFilters[header.value].length > 0
                              "
                              color="primary"
                              offset-y="10"
                            >
                              <v-menu
                                offset-y
                                left
                                :close-on-content-click="false"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    x-small
                                    color="grey"
                                    icon
                                    ><v-icon small>filter_alt</v-icon></v-btn
                                  >
                                </template>
                                <v-card
                                  style="
                                    background: var(--v-modal-base) !important;
                                    border-radius: 20px;
                                  "
                                >
                                  <v-card-text>
                                    <v-list
                                      dense
                                      style="
                                        background: var(
                                          --v-modal-base
                                        ) !important;
                                        border-radius: 20px;
                                      "
                                    >
                                      <v-text-field
                                        hide-details
                                        rounded
                                        dense
                                        outlined
                                        clearable
                                        prepend-inner-icon="search"
                                        placeholder="Search"
                                        autofocus
                                        v-model="searchPalletHeaders[header.value]"
                                        @input="updateSearchKey()"
                                      ></v-text-field>
                                      <v-list
                                        dense
                                        style="
                                          max-height: 50vh;
                                          overflow-y: auto;
                                          background: var(
                                            --v-modal-base
                                          ) !important;
                                        "
                                        :key="searchKey"
                                      >
                                        <v-list-item
                                          v-for="(item, index) in palletHeaderOptions(
                                            header.value
                                          )"
                                          :key="index"
                                        >
                                          <v-list-item-action class="pr-0">
                                            <v-checkbox
                                              v-model="palletFilters[header.value]"
                                              :value="item.value"
                                              hide-details
                                              dense
                                              @change="
                                                calculateFilteredPalletResults()
                                              "
                                            ></v-checkbox>
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title>
                                              {{ item.name }}
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item
                                          v-if="
                                            palletHeaderOptions(header.value)
                                              .length == 0
                                          "
                                        >
                                          <v-list-item-content
                                            class="text-center"
                                          >
                                            <span style="font-size: 12px"
                                              >No results found</span
                                            >
                                          </v-list-item-content>
                                        </v-list-item>
                                      </v-list>
                                    </v-list>
                                  </v-card-text>
                                </v-card>
                              </v-menu>
                            </v-badge>
                          </v-row>
                        </div>
                      </td>
                    </tr>
                  </table>
                  <v-divider></v-divider>
                  <v-virtual-scroll
                    v-if="filterPallets.length > 0"
                    :bench="10"
                    :items="filterPallets"
                    :height="calculatedPalletHeight"
                    :width="'100%'"
                    item-height="25"
                    class="px-0 mx-0"
                  >
                    <template v-slot:default="{ item }">
                      <div
                        class="text-center my-0 py-0"
                        :style="{
                          display: 'flex',
                          'flex-direction': 'row',
                          width: '100%',
                          'background-color': 'transparent',
                          'border-bottom': '0.25px solid grey',
                        }"
                      >
                        <td
                          v-for="(header, index) in palletHeaders"
                          :key="index"
                          :style="{ width: header.width ?? '100px' }"
                          class="text-center my-0 py-0"
                        >
                          <div v-if="header.value == 'status'">
                            <v-chip
                              @click="
                                addToPalletFilter(
                                  item[header.value],
                                  header.value
                                )
                              "
                              x-small
                              outlined
                              :color="getPalletStatusColor(item.status)"
                            >
                           <v-icon left small>{{ getPalletStatusIcon(item.status) }}</v-icon>    {{ item.status }}
                            </v-chip>
                          </div>
                          <div
                            v-else-if="
                              header.value == 'inspectionManifestDate' ||
                              header.value == 'intakeTime'
                            "
                          >
                            <v-chip
                              v-if="item[header.value]"
                              small
                              class="primaryText--text"
                              outlined
                              @click="
                                addToPalletFilter(
                                  item[header.value],
                                  header.value
                                )
                              "
                              style="border: none"
                            >
                              {{ formatDate(item[header.value]) }}
                            </v-chip>
                          </div>

                          <div v-else>
                            <v-chip
                              v-if="item[header.value]"
                              @click="
                                addToPalletFilter(
                                  item[header.value],
                                  header.value
                                )
                              "
                              small
                              class="primaryText--text"
                              outlined
                              style="border: none; font-size: 11px; z-index: 1"
                            >
                              {{ item[header.value] }}
                            </v-chip>
                          </div>
                        </td>
                      </div>
                    </template>
                  </v-virtual-scroll>
                </div>
              </v-col>
            </v-row>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="statusModal" width="400px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            <span class="headline">Bulk Update Status</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="statusModal = false"> X </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list dense>
            <v-list-item
              v-for="(status, index) in orderItemStatus.filter(
                (x) => x.name == 'Confirmed' || x.name == 'Rejected'
              )"
              :key="index"
            >
              <v-list-item-action>
                <v-icon :color="status.color">{{ status.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ status.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  @click="
                    (selectedStatus = status.name),
                      (confirmStatusModal = true),
                      (statusModal = false)
                  "
                >
                  <v-icon color="green">check</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmStatusModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card :loading="savingBulkStatus">
        <v-toolbar dense flat color="transparent">
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 16px">
            Are you sure you would like to update these statuses to
            {{ selectedStatus }}?
          </p>
          <v-row justify="center" class="mt-5 mb-3" v-if="!savingBulkStatus">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="(confirmStatusModal = false)"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              @click="bulkUpdateStatus()"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
  <script>
import dateFormat from "dateformat";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  data: () => ({
    bookingStatus: [
      { name: "Draft", icon: "edit_note", color: "#F57C00" },
      { name: "Pending", icon: "pending", color: "#009688" },
      { name: "Requested", icon: "verified", color: "#2196F3" },
      { name: "Confirmed", icon: "check_circle_outline", color: "#4CAF50" },
      { name: "Ghost", icon: "help", color: "#607D8B" },
      { name: "Cancelled", icon: "cancel", color: "#F44336" },
      { name: "Rejected", icon: "warning", color: "#F44336" },
    ],
    columnDrag: false,
    confirmStatusModal: false,
    details: {},
    displayItem: {},
    downloadingPalletExport: false,
    filters: null,
    filteredResults: [],
    filteredPalletResults: [],
    filteredValues: [],
    groupBy: [
      "ctoNo",
      "locationCode",
      "commodityCode",
      "varietyCode",
      "mark",
      "producerName",
      "inventoryCode",
      "grade",
      "packCode",
      "countSize",
      "palletQuantity",
      "cartonQuantity",
    ],
    headers: [
      {
        text: "Action",
        align: "center",
        disableSort: true,
        disableFilter: true,
        value: "actions",
        width: "100px",
      },
      {
        text: "Status",
        value: "stockStatus",
      },
      {
        text: "ID",
        value: "ctoNo",
      },
      {
        text: "Booking Status",
        value: "state",
      },
      {
        text: "Pallets",
        value: "totalPallets",
      },
      {
        text: "Allocated",
        value: "allocatedPallets",
      },
      {
        text: "Issues",
        value: "issuePallets",
      },
      {
        text: "Buyer",
        value: "buyerName",
        width: "220px",
      },
      {
        text: "Consignee",
        value: "consigneeName",
        width: "220px",
      },
      {
        text: "Vessel",
        value: "vesselVoyage",
      },
      {
        text: "POL",
        value: "portOfLoadValue",
      },
      {
        text: "POD",
        value: "portOfDischargeValue",
      },
      {
        text: "Final Destination",
        value: "finalDestinationValue",
      },
    ],
    includeComplete: false,
    isBulk: false,
    itemModal: false,
    loading: false,
    loadingDetails: false,
    loadingPallets: false,
    orders: [],
    orderStatus: [
      { name: "Planned", icon: "schedule", color: "#F57C00" },
      { name: "In Progress", icon: "pending", color: "blue" },
      { name: "Allocated", icon: "fact_check", color: "cyan" },
      { name: "Confirmed", icon: "verified", color: "teal" },
      { name: "Cancelled", icon: "cancel", color: "red" },
      { name: "Complete", icon: "check_circle_outline", color: "#4CAF50" },
    ],
    orderItemStatus: [
      { name: "In Progress", icon: "pending", color: "blue" },
      { name: "Allocated", icon: "fact_check", color: "cyan" },
      { name: "Confirmed", icon: "verified", color: "teal" },
      { name: "Rejected", icon: "verified", color: "orange" },
      { name: "Instruction Sent", icon: "email", color: "indigo" },
      { name: "Instruction Cancelled", icon: "warning", color: "orange" },
      { name: "Stuffed", icon: "check_circle_outline", color: "lime" },
      { name: "Stuff Discrepancy", icon: "error", color: "red" },
      { name: "Complete", icon: "check_circle_outline", color: "#4CAF50" },
      { name: "Cancelled", icon: "cancel", color: "red" },
    ],
    palletFilters: {},
    palletHeaders: [
      {
        text: "Status",
        value: "status",
        allowSort: true,
        allowFilter: true,
        width: "200px",
      },
      {
        text: "Location",
        value: "locationCode",
        allowSort: true,
        allowFilter: true,
        width: "100px",
      },
      {
        text: "CTO No",
        value: "ctoNo",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "Barcode",
        value: "sscc",
        allowSort: true,
        allowFilter: false,
        width: "150px",
      },
      {
        text: "Seq.",
        value: "sequenceNo",
        allowSort: true,
        allowFilter: false,
        width: "90px",
      },
      {
        text: "Pal. Qty",
        value: "palletQuantity",
        allowSort: true,
        allowFilter: false,
        width: "120px",
      },
      {
        text: "Ctns",
        value: "cartonQuantity",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Cust. Ref",
        value: "customerContainerRef",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "Comm.",
        value: "commodityCode",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Var.",
        value: "varietyCode",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Grade",
        value: "grade",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Count",
        value: "countSize",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Pack",
        value: "packCode",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Mark",
        value: "mark",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Inv. Code",
        value: "inventoryCode",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "Prod. Char",
        value: "productCharacteristic",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "Target Market",
        value: "targetMarket",
        allowSort: true,
        allowFilter: true,
        width: "180px",
      },
      {
        text: "Target Region",
        value: "targetRegion",
        allowSort: true,
        allowFilter: true,
        width: "180px",
      },
      {
        text: "Target Country",
        value: "targetCountry",
        allowSort: true,
        allowFilter: true,
        width: "180px",
      },
      {
        text: "PUC",
        value: "puc",
        allowSort: true,
        allowFilter: true,
        width: "120px",
      },
      {
        text: "Producer",
        value: "producerName",
        allowSort: true,
        allowFilter: true,
        width: "200px",
      },
      {
        text: "Orch.",
        value: "orchard",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "PHC",
        value: "phc",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Packhouse Name",
        value: "packhouseName",
        allowSort: true,
        allowFilter: true,
        width: "200px",
      },
      {
        text: "Insp. Date",
        value: "inspectionManifestDate",
        allowSort: true,
        allowFilter: true,
        width: "200px",
      },
      {
        text: "Insp. Age",
        value: "inspectionAge",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "Intake Waybill",
        value: "waybillNo",
        allowSort: true,
        allowFilter: true,
        width: "150px",
      },
      {
        text: "Loc. Intake Date",
        value: "intakeTime",
        allowSort: true,
        allowFilter: false,
        width: "150px",
      },
      {
        text: "Loc. Intake Age",
        value: "age",
        allowSort: true,
        allowFilter: false,
        width: "150px",
      },
      {
        text: "Nett",
        value: "nettWeight",
        allowSort: true,
        allowFilter: false,
        width: "150px",
      },
      {
        text: "Gross",
        value: "grossWeight",
        allowSort: true,
        allowFilter: false,
        width: "150px",
      },
    ],
    pallets: [],
    palletStatus: [
      { name: "Allocated", icon: "fact_check", color: "cyan" },
      { name: "Confirmed", icon: "verified", color: "teal" },
      { name: "In Transit", icon: "local_shipping", color: "teal" },
      { name: "Rejected", icon: "verified", color: "orange" },
      { name: "Stuffed", icon: "check_circle_outline", color: "lime" },
      { name: "Stuff Discrepancy", icon: "error", color: "red" },
      { name: "Complete", icon: "check_circle_outline", color: "#4CAF50" },
      { name: "Cancelled", icon: "cancel", color: "red" },
      { name: "Instruction Sent", icon: "email", color: "indigo" },

    ],
    palletTab: 0,
    products: {
      data: [],
    },
    productSummaryHeaders: [],
    allHeaders: [
      {
        text: "CTO No",
        value: "ctoNo",
        align: "center",
        group: true,
        selectable: true,
        show: true,
      },
      {
        text: "Location",
        value: "locationCode",
        align: "center",
        group: true,
        selectable: true,
        show: true,
      },
      {
        text: "Commodity",
        value: "commodityCode",
        align: "center",
        group: true,
        selectable: true,
        show: true,
      },
      {
        text: "Variety",
        value: "varietyCode",
        align: "center",
        group: true,
        selectable: true,
        show: true,
      },
      {
        text: "Mark",
        value: "mark",
        align: "center",
        group: true,
        selectable: true,
        show: true,
      },
      {
        text: "Producer Name",
        value: "producerName",
        align: "center",
        group: true,
        selectable: true,
        show: true,
      },
      {
        text: "Inventory Code",
        value: "inventoryCode",
        align: "center",
        group: true,
        selectable: true,
        show: true,
      },
      {
        text: "Grade/Class",
        value: "grade",
        align: "center",
        group: true,
        selectable: true,
        show: true,
      },
      {
        text: "Pack Code",
        value: "packCode",
        align: "center",
        group: true,
        selectable: true,
        show: true,
      },
      {
        text: "Count/Size",
        value: "countSize",
        align: "center",
        group: true,
        selectable: true,
        show: true,
      },
      {
        text: "No. Pallets",
        value: "palletQuantity",
        align: "center",
        isQuantity: true,
        required: true,
      },
      {
        text: "No. Cartons",
        value: "cartonQuantity",
        align: "center",
        isQuantity: true,
        required: true,
      },
      // {
      //     text: "Percentage",
      //     value: "percentage",
      //     align: "center",
      // }
    ],
    productKey: 300,
    saving: false,
    savingContainerStatus: false,
    savingBulkStatus: false,
    search: null,
    searchKey: 200,
    searchHeaders: {},
    searchPallets: null,
    searchPalletHeaders: {},
    selectAllItems: false,
    selectedItems: [],
    selectedItem: {},
    selectedStatus: null,
    sortBy: null,
    sortPalletBy: null,
    sortDesc: false,
    sortPalletDesc: false,
    statusModal: false,
    updateFilterValue: 100,
  }),

  watch: {
    selectAllItems(val) {
      if (val) {
        this.selectedItems = this.displayData;
      } else {
        this.selectedItems = [];
      }
    },
  },
  computed: {
    allocatedPallets() {
      return this.displayData
        .filter((x) => x.allocatedPallets)
        .reduce((acc, x) => acc + x.allocatedPallets, 0);
    },
    calculatedHeight() {
      let height = window.innerHeight;
      return height * 0.63 + "px";
    },
    calculatedPalletHeight() {
      let height = window.innerHeight;
      return height * 0.7 + "px";
    },
    displayData() {
      let result = this.filteredResults;
      if (this.search) {
        result = result.filter(
          (x) =>
            (x.orderNo &&
              x.orderNo.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.ctoNo &&
              x.ctoNo.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.containerNo &&
              x.containerNo.toLowerCase().includes(this.search.toLowerCase()))
        );
      }
      if (this.sortBy) {
        result = result.sort((a, b) => {
          if (this.sortDesc) {
            return a[this.sortBy] < b[this.sortBy] ? 1 : -1;
          } else {
            return a[this.sortBy] > b[this.sortBy] ? 1 : -1;
          }
        });
      }
      return result;
    },
    displayHeaders() {
      return this.productSummaryHeaders.filter((x) => x.show || x.required);
    },
    filterPallets() {
      let result = this.filteredPalletResults;
      if (this.searchPallets) {
        result = result.filter(
          (x) =>
            (x.sscc &&
              x.sscc
                .toLowerCase()
                .includes(this.searchPallets.toLowerCase())) ||
            (x.ctoNo &&
              x.ctoNo
                .toLowerCase()
                .includes(this.searchPallets.toLowerCase())) ||
            (x.containerNo &&
              x.containerNo
                .toLowerCase()
                .includes(this.searchPallets.toLowerCase()))
        );
      }
      if (this.sortPalletBy) {
        result = result.sort((a, b) => {
          if (this.sortPalletDesc) {
            return a[this.sortPalletBy] < b[this.sortPalletBy] ? 1 : -1;
          } else {
            return a[this.sortPalletBy] > b[this.sortPalletBy] ? 1 : -1;
          }
        });
      }
      return result;
    },
    issuePallets() {
      return this.displayData
        .filter((x) => x.issuePallets)
        .reduce((acc, x) => acc + x.issuePallets, 0);
    },
    productBreakdown() {
      return this.groupData();
    },
    stuffedPallets() {
      return this.displayData
        .filter((x) => x.loadedPallets)
        .reduce((acc, x) => acc + x.loadedPallets, 0);
    },
    palletTableWidth() {
      let result = 0;
      this.palletHeaders.forEach((x) => {
        result += parseInt(x.width ? x.width.replace("px", "") : 100);
      });
      return result + "px";
    },
    tableWidth() {
      let result = 0;
      this.headers.forEach((x) => {
        result += parseInt(x.width ? x.width.replace("px", "") : 150);
      });
      return result + "px";
    },
    modalWidth() {
      let result = 0;
      this.palletHeaders.forEach((x) => {
        result += parseInt(x.width ? x.width.replace("px", "") : 60);
      });
      return result + "px";
    },
    totalPallets() {
      return this.groupData()
        .filter((x) => x.totalPallets)
        .map((x) => x.totalPallets)
        .reduce((a, b) => a + b, 0);
    },
  },
  async created() {
    this.resetFilters();
    if (localStorage["orderItemFilters_" + this.$store.state.currentOrg.id]) {
      this.filters = JSON.parse(
        localStorage["orderItemFilters_" + this.$store.state.currentOrg.id]
      );
      if (typeof this.filters != "object") {
        this.resetFilters();
      }
      let result = [];
      let keys = Object.keys(this.filters);
      keys.forEach((x) => {
        result = [
          ...result,
          ...this.filters[x].map((y) => ({
            field: x,
            fieldName: this.headers.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.filteredValues = result;
    }
    if (!this.filters) {
      this.filters = {};
      this.resetFilters();
    }
    // if(!this.palletFilters){
    this.palletFilters = {};
    this.resetPalletFilters();
    // }
    this.getOrders();
    this.getProducts();
    this.productSummaryHeaders = [...this.allHeaders];
  },
  methods: {
    addToFilter(value, field) {
      if (this.filters[field].includes(value)) {
        this.filters[field] = this.filters[field].filter((x) => x != value);
      } else {
        this.filters[field].push(value);
      }
      this.updateFilterValue++;
      localStorage.setItem(
        "orderItemFilters_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.filters)
      );
      let keys = Object.keys(this.filters);
      let result = [];
      keys.forEach((x) => {
        result = [
          ...result,
          ...this.filters[x].map((y) => ({
            field: x,
            fieldName: this.headers.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.filteredValues = result;
      this.selectAllItems = false;
      this.selectedItems = [];
      this.calculateFilteredResults();
    },
    addToPalletFilter(value, field) {
      if (this.palletFilters[field].includes(value)) {
        this.palletFilters[field] = this.palletFilters[field].filter(
          (x) => x != value
        );
      } else {
        this.palletFilters[field].push(value);
      }
      this.updateFilterValue++;
      let keys = Object.keys(this.palletFilters);
      let result = [];
      keys.forEach((x) => {
        result = [
          ...result,
          ...this.palletFilters[x].map((y) => ({
            field: x,
            fieldName: this.palletHeaders.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.calculateFilteredPalletResults();
    },
    bookingStatusColor(status) {
      if (status) {
        let color = this.bookingStatus.find(
          (x) => x.name.toLowerCase() === status.toLowerCase()
        );
        return color ? color.color : "grey";
      } else return "grey";
    },
    bookingStatusIcon(status) {
      if (status) {
        let find = this.bookingStatus.find(
          (x) => x.name.toLowerCase() === status.toLowerCase()
        );
        return find ? find.icon : "";
      } else return "help";
    },
    async bulkUpdateStatus() {
      this.savingBulkStatus = true;
      let ids = this.selectedItems.filter(x=>x.stockStatus == 'Allocated').map((x) => x.id);
      await this.$API.bulkUpdateOrderItemStatus({
        items: ids,
        stockStatus: this.selectedStatus,
      });
      this.displayItem.allowConfirm = false
      this.savingBulkStatus = false;
      this.$message.success("Status Updated!");
      this.getOrders();
      this.confirmStatusModal = false;
    },
    constructGroups() {
      let groupBy = [...this.groupBy];
      let result = [];
      let initialCount = groupBy.length;
      while (initialCount > 0) {
        let group = groupBy.slice(0, initialCount);
        result.push(group);
        initialCount--;
      }
      return result;
    },
    changeSortBy(value) {
      if (this.sortBy == value) {
        if (this.sortDesc) {
          this.sortBy = null;
          this.sortDesc = false;
        } else {
          this.sortDesc = true;
        }
      } else {
        this.sortBy = value;
        this.sortDesc = false;
      }
      this.selectAllItems = false;
      this.selectedItems = [];
    },
    changePalletSortBy(value) {
      if (this.sortPalletBy == value) {
        if (this.sortPalletDesc) {
          this.sortPalletBy = null;
          this.sortPalletDesc = false;
        } else {
          this.sortPalletDesc = true;
        }
      } else {
        this.sortPalletBy = value;
        this.sortPalletDesc = false;
      }
    },
    calculateFilteredResults() {
      let filterKeys = Object.keys(this.filters);
      let filterResult = [];
      filterKeys.forEach((x) => {
        filterResult = [
          ...filterResult,
          ...this.filters[x].map((y) => ({
            field: x,
            fieldName: this.headers.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.filteredValues = filterResult;
      let result = [...this.orders];
      let keys = Object.keys(this.filters);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (this.filters[key] && this.filters[key].length > 0) {
          result = result.filter((x) => this.filters[key].includes(x[key]));
        }
      }
      this.filteredResults = result;
    },
    calculateFilteredPalletResults() {
      let filterKeys = Object.keys(this.palletFilters);
      let filterResult = [];
      filterKeys.forEach((x) => {
        filterResult = [
          ...filterResult,
          ...this.palletFilters[x].map((y) => ({
            field: x,
            fieldName: this.palletHeaders.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      let result = [...this.pallets];
      let keys = Object.keys(this.palletFilters);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (this.palletFilters[key] && this.palletFilters[key].length > 0) {
          result = result.filter((x) =>
            this.palletFilters[key].includes(x[key])
          );
        }
      }
      this.filteredPalletResults = result;
    },

    async downloadPalletExport(){
      this.downloadingPalletExport = true;
      let data = await this.$API.getStockExport({
        palletIds: this.filterPallets.map((x) => x.id),
      });
      var hiddenElement = document.createElement("a");
      hiddenElement.href =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
        data;
      hiddenElement.target = "_blank";
      hiddenElement.download = "Stock_" + new Date().getTime() + ".xlsx";
      hiddenElement.click();
      this.downloadingPalletExport = false;
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd/mm/yyyy");
      }
      return result;
    },
    async getOrders() {
      this.loading = true;
      this.orders = await this.$API.getOrderItems({
        params: {
          includeComplete: this.includeComplete,
        },
      });
      this.calculateFilteredResults();
      this.loading = false;
    },
    orderStatusColor(status) {
      if (status) {
        let color = this.orderStatus.find(
          (x) => x.name.toLowerCase() === status.toLowerCase()
        );
        return color ? color.color : "grey";
      } else return "grey";
    },
    orderStatusIcon(status) {
      if (status) {
        let find = this.orderStatus.find(
          (x) => x.name.toLowerCase() === status.toLowerCase()
        );
        return find ? find.icon : "";
      } else return "help";
    },
    orderItemStatusColor(status) {
      if (status) {
        let color = this.orderItemStatus.find(
          (x) => x.name.toLowerCase() === status.toLowerCase()
        );
        return color ? color.color : "grey";
      } else return "grey";
    },
    orderItemStatusIcon(status) {
      if (status) {
        let find = this.orderItemStatus.find(
          (x) => x.name.toLowerCase() === status.toLowerCase()
        );
        return find ? find.icon : "";
      } else return "help";
    },
    async getProducts() {
      let data = await this.$API.getProducts();
      this.products = data.data;
    },
    async getBulkAllocatedPallets(data) {
      this.loadingPallets = true;
      this.pallets = await this.$API.getBulkAllocatedPallets(data);
      this.calculateFilteredPalletResults();
      this.loadingPallets = false;
    },
    async getAllocatedPallets(id) {
      this.loadingPallets = true;
      this.pallets = await this.$API.getAllocatedPallets(id);
      this.loadingPallets = false;
    },
    async getOrderItemDetails(id) {
      this.loadingDetails = true;
      this.details = await this.$API.getOrderItemDetail(id);
      this.loadingDetails = false;
    },
    headerOptions(value) {
      let result = [
        ...new Set(
          this.orders.map((x) => ({
            name: x[value] ?? "(Blank)",
            value: x[value],
          }))
        ),
      ];
      let uniqueValues = [...new Set(result.map((x) => x.value))];
      if (this.searchHeaders[value]) {
        uniqueValues = uniqueValues.filter(
          (x) =>
            x &&
            x.toLowerCase().includes(this.searchHeaders[value].toLowerCase())
        );
      }
      result = uniqueValues.map((x) => result.find((y) => y.value === x));
      result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      return result;
    },
    palletHeaderOptions(value) {
      let result = [
        ...new Set(
          this.pallets.map((x) => ({
            name: x[value] ?? "(Blank)",
            value: x[value],
          }))
        ),
      ];
      let uniqueValues = [...new Set(result.map((x) => x.value))];
      if (this.searchPalletHeaders[value]) {
        uniqueValues = uniqueValues.filter(
          (x) =>
            x &&
            x
              .toLowerCase()
              .includes(this.searchPalletHeaders[value].toLowerCase())
        );
      }
      result = uniqueValues.map((x) => result.find((y) => y.value === x));
      result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      return result;
    },
    productSpec(item) {
      let keys = [
        { key: "puc", value: "PUC" },
        { key: "phc", value: "PHC" },
        { key: "packCode", value: "PACK" },
        { key: "grade", value: "Grade" },
        { key: "countSize", value: "Count" },
        { key: "inventoryCode", value: "Inventory Code" },
      ];
      let result = [];
      for (let i = 0; i < keys.length; i++) {
        if (item[keys[i].key]) {
          result.push(keys[i].value + ": " + item[keys[i].key]);
        }
      }
      return result.join(" - ");
    },
    resetFilters() {
      this.filters = {};
      let keys = this.headers.map((x) => x.value);
      keys.forEach((x) => {
        this.searchHeaders[x] = null;
        this.filters[x] = [];
      });
      this.filteredValues = [];
      this.selectedItems = [];
      this.selectAllItems = false;
      this.updateFilterValue++;
      localStorage.removeItem(
        "orderItemFilters_" + this.$store.state.currentOrg.id
      );
      this.calculateFilteredResults();
    },
    resetPalletFilters() {
      this.palletFilters = {};
      let keys = this.palletHeaders.map((x) => x.value);
      keys.forEach((x) => {
        this.searchPalletHeaders[x] = null;
        this.palletFilters[x] = [];
      });
      this.calculateFilteredPalletResults();
    },
    async saveItem() {
      this.saving = true;
      await this.$API.updateOrderItem({
        id: this.selectedItem.id,
        stockStatus: this.selectedItem.stockStatus,
      });
      this.saving = false;
      this.itemModal = false;
      this.$message.success("Order Item Updated");
      this.getOrders();
    },
    selectItem(item) {
      let find = this.selectedItems.find((x) => x.id == item.id);
      if (!find) {
        this.selectedItems.push(item);
      } else {
        this.selectedItems = this.selectedItems.filter((x) => x.id != item.id);
      }
    },
    totalNettWeight() {
      return this.pallets
        .filter((x) => x.nettWeight)
        .reduce((acc, x) => acc + parseFloat(x.nettWeight), 0)
        .toFixed(2);
    },
    totalGrossWeight() {
      return this.pallets
        .filter((x) => x.grossWeight)
        .reduce((acc, x) => acc + parseFloat(x.grossWeight), 0)
        .toFixed(2);
    },
    totalSummaryCartons() {
      return this.pallets
        .filter((x) => x.cartonQuantity)
        .reduce((acc, x) => acc + x.cartonQuantity, 0);
    },
    totalSummaryPallets() {
      return [...new Set(this.pallets.map((x) => x.sscc))].length;
    },
    updateSearchKey() {
      this.searchKey++;
    },
    viewOrderItems() {
      this.isBulk = true;
      this.displayItem = {};
      let uniqueVesselVoyages = [
        ...new Set(this.selectedItems.map((x) => x.vesselVoyage)),
      ].filter(Boolean);
      if (uniqueVesselVoyages.length > 1) {
        this.$message.error("Selected items must have the same vessel voyage");
        return;
      } else if(uniqueVesselVoyages.length > 0) {
        this.displayItem.vesselVoyage = uniqueVesselVoyages[0];
      }

      let uniqueBuyers = [...new Set(this.selectedItems.map((x) => x.buyerName))].filter(Boolean);
      if (uniqueBuyers.length > 1) {
        this.displayItem.buyerName = 'Multiple'
      } else if(uniqueBuyers.length > 0) {
        this.displayItem.buyerName = uniqueBuyers[0];
      }

      let uniqueConsignees = [
        ...new Set(this.selectedItems.map((x) => x.consigneeName)),
      ].filter(Boolean);
      if (uniqueConsignees.length > 1) {
        this.displayItem.consigneeName = 'Multiple'
      } else if(uniqueConsignees.length > 0) {
        this.displayItem.consigneeName = uniqueConsignees[0];
      }
      if(this.selectedItems.filter(x=>x.stockStatus == 'Allocated').length == this.selectedItems.length){
        this.displayItem.allowConfirm = true;
      } else {
        this.displayItem.allowConfirm = false;
      }
      this.getBulkAllocatedPallets(this.selectedItems.map((x) => x.id));
      // this.getOrderItemDetails(item.id);
      this.itemModal = true;
    },
    viewOrderItem(item) {
      this.isBulk = true;
      this.selectedItems = [item];
      this.viewOrderItems();
    },
    viewOrder(item) {
      this.$router.push({
        path: "/order/" + item.systemReference,
      });
    },

    getHeaderDetail(group) {
      switch (group) {
        case "phc":
          return {
            text: "Packhouse",
            value: "phc",
            align: "center",
            initialValue: group,
          };
        case "productId":
          return {
            text: "Product",
            value: "product",
            align: "center",
            initialValue: group,
          };
        case "varietyProductId":
          return {
            text: "Variety",
            value: "varietyProduct",
            align: "center",
            initialValue: group,
          };
        case "packCode":
          return {
            text: "Packcode",
            value: "packCode",
            align: "center",
            initialValue: group,
          };
        case "grade":
          return {
            text: "Grade",
            value: "grade",
            align: "center",
            initialValue: group,
          };
        case "countSize":
          return {
            text: "Count",
            value: "countSize",
            align: "center",
            initialValue: group,
          };
        case "status":
          return {
            text: "status",
            value: "Status",
            align: "center",
            initialValue: group,
          };
        case "cartonQuantity":
          return {
            text: "No. Cartons",
            value: "cartonQuantity",
            align: "center",
            initialValue: group,
          };
        default:
          return {
            text: group,
            value: group,
            align: "center",
            initialValue: group,
          };
      }
    },
    formatObjects(result) {
      if (this.groupBy.includes("productId")) {
        result = result.map((item) => {
          if (item.productId) {
            let findProduct = this.products.find(
              (product) => product.id == item.productId
            );
            item.productId = findProduct ? findProduct.name : null;
          }
          return item;
        });
      }
      if (this.groupBy.includes("varietyProductId")) {
        result = result.map((item) => {
          if (item.varietyProductId) {
            let findProduct = this.products.find(
              (product) => product.id == item.varietyProductId
            );
            item.varietyProductId = findProduct ? findProduct.name : null;
          }
          return item;
        });
      }
      return result;
    },
    getPalletStatusColor(status){
      let find = this.palletStatus.find(x => x.name == status);
      return find ? find.color : 'grey';
    },
    getPalletStatusIcon(status){
      let find = this.palletStatus.find(x => x.name == status);
      return find ? find.icon : 'help';
    },
    groupData() {
      let pallets = JSON.parse(JSON.stringify(this.pallets));
      let result = [];
      let groups = this.constructGroups();
      groups.reverse();
      let uniqueGroups = [];
      let total = pallets.reduce((a, b) => a + parseInt(b.cartonQuantity), 0);
      let maxLevel = 0;
      for (let i = 0; i < groups.length; i++) {
        for (let j = 0; j < pallets.length; j++) {
          if (!pallets[j].groups) {
            pallets[j].groups = [];
          }
          let values = groups[i].map((x) => pallets[j][x]);
          let hash = values.join(":");
          pallets[j].groups.push(hash);
          if (!uniqueGroups.includes(hash)) {
            uniqueGroups.push(hash);
          }
        }
        let productResult = [];
        for (let j = 0; j < uniqueGroups.length; j++) {
          let groupPallets = pallets.filter(
            (x) => x.groups[i] && x.groups[i] == uniqueGroups[j]
          );
          if (groupPallets.length > 0) {
            let detail = groupPallets[0];
            let parent = null;
            if (i > 0) {
              parent = detail.groups[i - 1];
            }
            maxLevel = i + 1;
            let obj = {
              level: i + 1,
              hash: uniqueGroups[j],
              parent: parent,
              cartonQuantity: groupPallets.reduce(
                (a, b) => a + parseInt(b.cartonQuantity),
                0
              ),
              palletQuantity:
                Math.round(
                  groupPallets.reduce(
                    (a, b) => a + parseFloat(b.palletQuantity),
                    0
                  ) * 100
                ) / 100,
              percentage:
                Math.round(
                  (groupPallets.reduce(
                    (a, b) => a + parseInt(b.cartonQuantity),
                    0
                  ) /
                    total) *
                    100 *
                    100
                ) /
                  100 +
                "%",
            };
            // console.log(groupPallets.reduce((a, b) => a + parseInt(b.palletQuantity), 0), groupPallets.map(b=>parseInt(b.palletQuantity)))
            for (let k = 0; k < groups.at(-1).length; k++) {
              let findGroup = this.getHeaderDetail(groups.at(-1)[k]);
              obj[findGroup.initialValue] = detail[findGroup.initialValue];
              if(!obj[findGroup.initialValue]){
                obj[findGroup.initialValue] = '(none)';
              }
            }
            productResult.push(obj);
          }
        }
        result = [...result, ...productResult];
      }
      for (let i = 0; i < result.length; i++) {
        result[i].children = result.filter(
          (x) => x.parent == result[i].hash
        ).length;
      }
      result = result.filter((x) => x.level == maxLevel);
      result.sort((a, b) =>
        a.parent > b.parent ? 1 : b.parent > a.parent ? -1 : 0
      );
      let fields = groups
        .at(-1)
        .slice(0, groups.length - 1)
        .map((x) => this.getHeaderDetail(x).initialValue);
      // Sort data
      for (let i = 0; i < fields.length; i++) {
        let filteredFields = fields.filter((x, index) => index <= i);
        for (let j = 0; j < result.length; j++) {
          if (!result[j].parentHash) {
            result[j].parentHash = [];
          }
          if (!result[j].referenceHash) {
            result[j].referenceHash = [];
          }
          let values = filteredFields.map((x) => result[j][x]);
          let hash = values.join(":");
          result[j].parentHash.push(hash);
          result[j]["referenceHash" + "_" + i] = result[j].parentHash.join("_");
          result[j].referenceHash.push(result[j].parentHash.join("_"));
        }
      }
      let sortArray = result;
      let initialAlphabet = [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ];
      let alphabet = [];
      for (let i = 0; i < 10; i++) {
        let lastLetter = i > 0 ? initialAlphabet[i - 1] : "";
        initialAlphabet.forEach((x) => {
          alphabet.push(lastLetter + x);
        });
      }
      for (let i = 0; i < groups.length - 1; i++) {
        let uniqueReferences = [
          ...new Set(result.map((x) => x.referenceHash[i])),
        ];
        for (let j = 0; j < uniqueReferences.length; j++) {
          let filter = result.filter(
            (x) => x.referenceHash[i] == uniqueReferences[j]
          );
          filter.sort((a, b) =>
            a[fields[i]] > b[fields[i]]
              ? 1
              : b[fields[i]] > a[fields[i]]
              ? -1
              : 0
          );
          for (let k = 0; k < filter.length; k++) {
            let findIndex = sortArray.findIndex(
              (x) => x.hash == filter[k].hash
            );
            if (!sortArray[findIndex]["Index"]) {
              sortArray[findIndex]["Index"] = [];
            }
            sortArray[findIndex]["Index"].push(alphabet[j]);
            sortArray[findIndex]["Index_" + i] = alphabet[j];
          }
        }
      }
      sortArray = sortArray.map((x) => ({ ...x, index: x.Index.join("") }));
      sortArray.sort((a, b) =>
        a.index > b.index ? 1 : b.index > a.index ? -1 : 0
      );
      result = sortArray;
      let uniqueParents = [...new Set(result.map((x) => x.parent))];
      let finalResult = [];
      for (let i = 0; i < uniqueParents.length; i++) {
        let filtered = result.filter((x) => x.parent == uniqueParents[i]);
        for (let j = 0; j < fields.length; j++) {
          let uniqueValues = [...new Set(filtered.map((x) => x[fields[j]]))];
          for (let k = 0; k < uniqueValues.length; k++) {
            let firstIndex = filtered.findIndex(
              (x) => x[fields[j]] == uniqueValues[k]
            );
            for (let l = 0; l < filtered.length; l++) {
              if (l == firstIndex) {
                filtered[l][fields[j]] = uniqueValues[k];
                filtered[l][fields[j] + "_rowspan"] = filtered.length;
              } else {
                filtered[l][fields[j]] = null;
              }
            }
          }
        }
        finalResult = [...finalResult, ...filtered];
      }
      for (let i = 0; i < fields.length; i++) {
        let indices = [...new Set(finalResult.map((x) => x["Index_" + i]))];
        for (let j = 0; j < indices.length; j++) {
          let values = [
            ...new Set(
              finalResult
                .filter((x) => x["Index_" + i] == indices[j])
                .map((x) => x[fields[i]])
                .filter(Boolean)
            ),
          ];
          for (let k = 0; k < values.length; k++) {
            let findFirstIndex = finalResult.findIndex(
              (x) => x["Index_" + i] == indices[j] && x[fields[i]] == values[k]
            );
            let filter = finalResult.filter(
              (x) => x["Index_" + i] == indices[j] && x[fields[i]] == values[k]
            );
            for (let l = 0; l < filter.length; l++) {
              let filterIndex = finalResult.findIndex(
                (x) => x.hash == filter[l].hash
              );
              if (filterIndex != findFirstIndex) {
                finalResult[filterIndex][fields[i]] = null;
                finalResult[filterIndex][fields[i] + "_rowspan"] = null;
              } else {
                finalResult[filterIndex][fields[i] + "_rowspan"] = filter
                  .map((x) => x[fields[i] + "_rowspan"])
                  .filter(Boolean)
                  .reduce((a, b) => a + b);
              }
            }
          }
        }
      }
      finalResult = this.formatObjects(finalResult);
      return finalResult;
    },
    sum(field) {
      return (
        (Math.round(
          this.groupData()
            .map((x) => x[field])
            .reduce((a, b) => a + b, 0)
        ) *
          100) /
        100
      );
    },

    updateSelectedHeaders() {
      this.productSummaryHeaders = this.allHeaders.filter(
        (x) => x.show || x.required
      );
      // console.log(this.productSummaryHeaders)
      this.updateHeaderSelection();
      this.productKey++;
    },
    updateHeaderSelection() {
      this.groupBy = this.productSummaryHeaders
        .filter((x) => x.group && x.show)
        .map((x) => x.value);
      // this.updateSelectedHeaders()
      this.productKey++;
    },
  },
};
</script>
    
  <style scoped>
.container {
  width: 100%;
  height: 70vh;
  margin: auto;
  /* outline: solid 1px black; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.ghost-card {
  opacity: 0.8;
  background: var(--v-modal-base) !important;
  border: 1px solid var(--v-primary-base);
  /* padding: 10px; */
}
</style>